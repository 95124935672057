import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const photos = graphql`
  query PhotosQuery {
   allContentfulPhotos {
    edges {
      node {
        photo {
          fixed(width:1200) {
            width
            height
            src
            srcSet
          }
        },
        text 
        }
      }
    }
  }
`

const PhotosPage = ({data}) => (
  <Layout>
    <SEO title="Photos" />
    <h1>Photos</h1>
   <div style={{display: `flex`, flexWrap: `wrap`}}>
    {data.allContentfulPhotos && data.allContentfulPhotos.edges.map(photo => { 
      return ( <div style={{ margin: `0 auto`, maxWidth: 350, padding: `0 1rem` }}><img src={photo.node.photo.fixed.src} alt={photo.node.text}/></div>)
    })
  }
  </div>

  </Layout>
)

export default PhotosPage
